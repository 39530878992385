<template>
  <v-dialog v-model="modelValue" v-if="modelValue" max-width="540">
    <v-card class="post-individual-listing-dialog">
      <v-btn class="close-button" icon color="#b4b4b4" @click="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-card-title class="header">
        <span class="header__title">
          {{ $t("map.post_listing_modal.post_listing") }}</span
        >
      </v-card-title>

      <v-card-text class="pa-0 mb-6">
        <div class="info-text">
          <p>
            {{ $t("map.post_listing_modal.same_credentials_text") }}
          </p>
          <p class="mb-0">
            {{ $t("map.post_listing_modal.additional_info_text") }}
          </p>
        </div>
      </v-card-text>

      <div class="mb-10">
        <v-form lazy-validation ref="form" v-model="isFormValid">
          <v-autocomplete
            v-model="country"
            :items="countryList"
            :item-text="`country_name_${$i18n.locale}`"
            :no-data-text="$t('calc.no_country_text')"
            :label="
              $t('landingView.propertyAdvSection.itemCard.chipInfo.citizenship')
            "
            :rules="[rules.isRequired]"
            item-value="id"
            color="#2B2F3C"
            class="mb-6"
            persistent-hint
            outlined
            hide-details
          />

          <maz-phone-number-input
            class="mb-6 post-listing-dialog_phone-number-input"
            v-model="phone"
            show-code-on-list
            :translations="{
              countrySelectorLabel: $t('propertyView.country_code'),
              countrySelectorError: $t('propertyView.choose_country'),
              phoneNumberLabel: $t('propertyView.phone_number'),
              example: $t('propertyView.example'),
            }"
            default-country-code="TR"
            :preferred-countries="['TR', 'RU', 'BY', 'KZ', 'UA']"
            no-search
            :error="isPhoneFieldInvalid"
            @update="onPhoneFieldUpdate"
          ></maz-phone-number-input>

          <v-checkbox
            v-model="is_agreed_to_terms_of_use"
            required
            class="agreements-section__checkbox"
            color="#0062FF"
            hide-details
            :rules="[(v) => !!v || 'You must agree to continue!']"
          >
            <template v-slot:label>
              {{ $t("profile.agree_with") }}

              <a
                @click.stop.prevent="onLegalAgreementText"
                class="legal_agreement_text"
              >
                {{ $t("profile.with_terms_of_partner_service") }}
              </a>
            </template>
          </v-checkbox>

          <v-checkbox
            v-model="is_agreed_to_get_notifications"
            class="agreements-section__checkbox"
            color="#0062FF"
            hide-details
          >
            <template v-slot:label>
              {{ $t("profile.agree_service_messages") }}
            </template>
          </v-checkbox>
        </v-form>
      </div>

      <v-card-actions class="pa-0">
        <v-btn
          depressed
          color="#fecc00"
          block
          large
          @click="onContinueClick"
          :loading="isLoading"
        >
          {{ $t("map.post_listing_modal.continue") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { api } from "@/api";
import { eventBus } from "@/main";
import CryptoJS from "crypto-js";

export default {
  name: "PostListingIndividualDialog",
  props: {
    value: Boolean,
  },

  data() {
    return {
      isLoading: false,
      isFormValid: true,
      isPhoneFieldInvalid: false,
      countryList: [],
      country: null,
      phone: null,
      is_agreed_to_terms_of_use: false,
      is_agreed_to_get_notifications: false,
    };
  },

  async mounted() {
    this.countryList = await this.getCountries();

    if (this.$store.getters.user?.profile?.country) {
      this.country = this.$store.getters.user?.profile?.country;
    }
  },

  watch: {
    modelValue(value) {
      if (!value) {
        this.phone = null;
      }

      if (this.$store.getters.user?.profile?.country) {
        this.country = this.$store.getters.user?.profile?.country;
      }
    },
  },

  methods: {
    closeModal() {
      this.modelValue = false;
      this.isPhoneFieldInvalid = false;
      this.phone = null;
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
    },

    async getCountries() {
      const response = await api.get("/registration/reg-countries/");
      return response.data;
    },

    encryptToken(token) {
      const encryptionKey = process.env.VUE_APP_ENCRYPTION_KEY;

      return CryptoJS.AES.encrypt(token, encryptionKey).toString();
    },

    onPhoneFieldUpdate() {
      if (this.phone !== "") {
        this.isPhoneFieldInvalid = false;
      }
    },

    async onContinueClick() {
      const isValid = this.$refs.form.validate();

      if (String(this.phone).trim() === "" || this.phone === null) {
        this.isPhoneFieldInvalid = true;

        return;
      }

      if (!isValid) return;

      const encryptedToken = this.encryptToken(
        this.$store.getters.tokens.refresh_token
      );
      const encodedToken = encodeURIComponent(encryptedToken);

      this.isLoading = true;

      try {
        await api.post("/partners/register_or_get/", {
          citezenship: this.country,
          phone: this.phone,
          service_agreement: this.is_agreed_to_get_notifications,
        });

        const response = await api.get("auth/user");

        if (response) {
          this.$store.dispatch("setUser", response.data);
        }

        window
          .open(`${this.partnerPortalUrl}?auth=${encodedToken}`, "_blank")
          ?.focus();

        this.closeModal();
      } catch (error) {
        eventBus.$emit("showSnackbar", {
          text: error.response.data.detail,
          color: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    onLegalAgreementText() {
      eventBus.$emit("legalClick", {
        tr: "partner_terms_of_service",
        btn: "Terms of service",
      });
    },
  },

  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },

    rules() {
      return {
        isRequired: (v) => !!v || "Name is required",
      };
    },

    partnerPortalUrl() {
      return `${process.env.VUE_APP_PARTNER_PORTAL_URL}/${this.$i18n.locale}`;
    },
  },
};
</script>

<style lang="scss">
.post-listing-dialog_phone-number-input {
  flex: 1;
  &.maz-phone-number-input .country-selector {
    width: 135px;
    min-width: 135px;
    max-width: 135px;
    flex: 0 0 135px;
  }

  & .maz-select__options-list__item.selected .maz-text-muted {
    color: #ffffff;
  }

  & .maz-select__options-list {
    width: 300px !important;
    max-width: 300px !important;
    max-height: 200px !important;
  }

  div[data-lastpass-icon-root] {
    display: none;
  }

  & .maz-base-component.maz-input {
    height: 56px;
  }

  & .maz-phone-number-input__country-flag {
    bottom: 12px;
  }
}
</style>

<style scoped lang="scss">
// ::v-deep(.post-listing-dialog_phone-number-input) {
//   flex: 1;
//   &.maz-phone-number-input .country-selector {
//     width: 135px;
//     min-width: 135px;
//     max-width: 135px;
//     flex: 0 0 135px;
//   }

//   & .maz-select__options-list__item.selected .maz-text-muted {
//     color: #ffffff;
//   }

//   & .maz-select__options-list {
//     width: 300px !important;
//     max-width: 300px !important;
//     max-height: 200px !important;
//   }

//   div[data-lastpass-icon-root] {
//     display: none;
//   }

//   & .maz-base-component.maz-input {
//     height: 56px;
//   }

//   & .maz-phone-number-input__country-flag {
//     bottom: 12px;
//   }
// }

.info-text {
  background-color: rgba(254, 204, 0, 0.1);
  padding: 8px 16px;
  border-radius: 4px;

  p {
    font-size: 14px;
    color: #3d3d3d;
  }
}

.post-individual-listing-dialog {
  position: relative;
  padding: 32px 40px;

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 24px;
    padding: 0;

    &__title {
      font-size: 26px;
      font-weight: 700;
      color: #212121;
      margin-bottom: 8px;
    }
  }

  .legal_agreement_text {
    display: contents;
    color: #0062ff;
    margin-left: 4px;
  }
}
</style>
