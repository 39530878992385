import Vue from "vue";
import App from "./App.vue";
//import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueGtag from "vue-gtag";
import i18n from "./i18n";
import VueMeta from "vue-meta";
import Ads from "vue-google-adsense";
import store from "./store";
import { VueMaskDirective } from "v-mask";
import SnackBarGlobal from "@/components/Common/SnackBarGlobal";
import { MazPhoneNumberInput } from "maz-ui";
import "@/scss/maz.css";
import "@/assets/colors.css";
import "@/assets/styles.scss";
import "@mdi/font/css/materialdesignicons.css";

Vue.component("SnackBarGlobal", SnackBarGlobal);

Vue.directive("mask", VueMaskDirective);

Vue.use(require("vue-script2"));

Vue.use(Ads.Adsense);

Vue.use(VueMeta);

Vue.use(MazPhoneNumberInput);

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_G_TAG_ID,
  },
});

const eventBus = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
});

eventBus.$mount("#app");

export { eventBus };
